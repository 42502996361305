import { ScreenFragment } from 'entities/assessment'
import { fabric } from 'fabric'

interface DrawDarkRectsParams {
  canvas: fabric.Canvas
  fragment: ScreenFragment | undefined
}

export const drawDarkRects = ({ canvas, fragment }: DrawDarkRectsParams) => {
  const darkRectObjects = canvas.getObjects('dark')

  if (fragment && !fragment.root) {
    const hasDarkRectWithCurrentFragmentId = darkRectObjects.some(
      (item) => item.data?.fragmentId === fragment.id
    )

    if (hasDarkRectWithCurrentFragmentId) {
      return
    }

    canvas.remove(...darkRectObjects)

    const topMaskRect = new fabric.Rect({
      left: 0,
      top: 0,
      originX: 'left',
      originY: 'top',
      width: canvas.width,
      height: fragment.position.y,
      fill: 'rgba(0, 0, 0, 0.5)',
      type: 'dark',
      transparentCorners: false,
      selectable: false,
      hasBorders: false,
      data: {
        fragmentId: fragment?.id,
      },
    })

    const bottomMaskRect = new fabric.Rect({
      left: 0,
      top: fragment.position.y + fragment.height,
      originX: 'left',
      originY: 'top',
      width: canvas.width,
      height: Number(canvas.height) - fragment.position.y,
      fill: 'rgba(0, 0, 0, 0.5)',
      type: 'dark',
      transparentCorners: false,
      selectable: false,
      hasBorders: false,
      data: {
        fragmentId: fragment?.id,
      },
    })

    const leftMaskRect = new fabric.Rect({
      left: 0,
      top: fragment.position.y,
      originX: 'left',
      originY: 'top',
      width: fragment.position.x,
      height: fragment.height,
      fill: 'rgba(0, 0, 0, 0.5)',
      type: 'dark',
      transparentCorners: false,
      selectable: false,
      hasBorders: false,
      data: {
        fragmentId: fragment?.id,
      },
    })

    const rightMaskRect = new fabric.Rect({
      left: fragment.position.x + fragment.width,
      top: fragment.position.y,
      originX: 'left',
      originY: 'top',
      width: Number(canvas.width) - fragment.position.x,
      height: fragment.height,
      fill: 'rgba(0, 0, 0, 0.5)',
      type: 'dark',
      transparentCorners: false,
      selectable: false,
      hasBorders: false,
      data: {
        fragmentId: fragment?.id,
      },
    })

    canvas.add(topMaskRect)
    canvas.add(bottomMaskRect)
    canvas.add(leftMaskRect)
    canvas.add(rightMaskRect)
  } else {
    canvas.remove(...darkRectObjects)
  }
}
