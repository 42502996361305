import { axiosInstance } from 'shared/api/axios'
import { RootObject } from './api-types'

export const fetchHeuristics = async () => {
  const { data } = await axiosInstance.get<RootObject>(
    'https://backend.prodmaps.com/en/api135a/node/evristika/?include=field_materials_ru,field_materials_en,field_htags,field_field_htags_en,field_heuristics_influenced,field_he3_he1_list,field_he3_link_to_he1_parent,field_recommendation_how_to_fix,field_recommendation_how_to_fix.field_fix_examples_ru,field_recommendation_how_to_fix.field_fix_examples_ru.field_media,field_recommendation_how_to_fix.field_fix_examples_ru.field_media.field_example_image,field_recommendation_how_to_fix.field_fix_examples_ru.field_media.field_example_file,field_recommendation_how_to_fix.field_fix_examples_ru.field_media.field_video_v2,field_recommendation_how_to_fix.field_fix_examples_ru.field_media.field_video_v2.thumbnail,field_recommendation_how_to_fix.field_fix_examples_ru.field_media.field_video_v2.field_media_video_file,field_recommendation_how_to_fix,field_recommendation_how_to_fix.field_fix_examples_en,field_recommendation_how_to_fix.field_fix_examples_en.field_media,field_recommendation_how_to_fix.field_fix_examples_en.field_media.field_example_image,field_recommendation_how_to_fix.field_fix_examples_en.field_media.field_example_file,field_recommendation_how_to_fix.field_fix_examples_en.field_media.field_video_v2,field_recommendation_how_to_fix.field_fix_examples_en.field_media.field_video_v2.thumbnail,field_recommendation_how_to_fix.field_fix_examples_en.field_media.field_video_v2.field_media_video_file&jsonapi_include=1&page[limit]=999'
  )

  return data.data
}
