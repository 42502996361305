import { useLocation } from 'react-router-dom'
import { useProject } from 'shared/model/projects'

type Permission = 'READ' | 'WRITE'

interface UsePermissionReturn {
  permission: Permission
}

export const usePermission = (): UsePermissionReturn => {
  const { pathname } = useLocation()

  const { data: project } = useProject()

  const hasWritePermission = !pathname.includes('/project') && !project?.fun

  return {
    permission: hasWritePermission ? 'WRITE' : 'READ',
  }
}

export const useHasWritePermission = () => {
  const { permission } = usePermission()

  return permission === 'WRITE'
}
