import { Datum as HeuristicType } from 'entities/assessment/api-types'
import s from './ReportDetails.module.css'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { RecommendationExamples } from './RecommendationExamples'
import { getParagraphsFromString } from 'shared/lib/string'
import { isEnglishProject } from 'shared/lib/language'
import { useState } from 'react'

import { ReactComponent as CloseIcon } from '../../../../assets/close.svg'
import { ReactComponent as VideoIcon } from '../../../../assets/video.svg'
import { ReactComponent as ArticleIcon } from '../../../../assets/article.svg'
import { ReactComponent as OkIcon } from '../../../../assets/ok.svg'

type Recommendation = Record<string, any>

interface ReportDetailsContentProps {
  projectId: string
  heuristic: HeuristicType | undefined
  recommendation: Recommendation | undefined
  isMobile?: boolean
  onClose?: VoidFunction
  className?: string
}

export const ReportDetailsContent = ({
  heuristic,
  projectId,
  recommendation,
  className,
  onClose,
}: ReportDetailsContentProps) => {
  const [isMaterialsVisible, setMaterialsVisible] = useState(false)
  const [isAllTagsVisible, setAllTagsVisible] = useState(false)

  const { t } = useTranslation()

  const isEnglish = isEnglishProject(projectId)

  const isRuMaterialsEmpty =
    (Array.isArray(heuristic?.field_materials_ru) &&
      heuristic?.field_materials_ru.length === 0) ||
    !Array.isArray(heuristic?.field_materials_ru)

  const heuristicMaterialsValue =
    isEnglish || isRuMaterialsEmpty
      ? heuristic?.field_materials_en
      : heuristic?.field_materials_ru

  const heuristicMaterials: any = Array.isArray(heuristicMaterialsValue)
    ? heuristicMaterialsValue
    : []

  const articles = heuristicMaterials.filter(
    (item: any) => item.field_material_type === 'Article'
  )
  const videos = heuristicMaterials.filter(
    (item: any) => item.field_material_type === 'Video'
  )

  const renderMaterial = (material: any, type: 'article' | 'video') => (
    <div key={material.id} className={s.material}>
      {type === 'article' ? <ArticleIcon /> : <VideoIcon />}
      <a
        target="_blank"
        rel="noreferrer"
        href={material.field_link_url_v1}
        className={s.link}
      >
        {material.field_link_text_v1}
      </a>
    </div>
  )

  const goodExamplesValue = isEnglish
    ? recommendation?.field_fix_examples_en
    : recommendation?.field_fix_examples_ru

  const goodExamples = Array.isArray(goodExamplesValue)
    ? goodExamplesValue.filter(
        (example: any) =>
          Number(example.field_implementation) > 3 &&
          example.field_shown_to !== 'under_nda'
      )
    : []
  const badExamples = Array.isArray(goodExamplesValue)
    ? goodExamplesValue.filter(
        (example: any) =>
          Number(example.field_implementation) <= 3 &&
          example.field_shown_to !== 'under_nda'
      )
    : []

  const renderRecommendations = () => (
    <>
      <div className={classNames(s.heuristicContent)}>
        <div className={classNames(s.recommendationHeader)}>
          <div className={classNames(s.good, s.recommendationIcon)}>
            <OkIcon />
          </div>
          <Typography
            sx={{
              fontSize: '16px',
            }}
          >
            {t('correct')}
          </Typography>
        </div>
        <Typography
          sx={{
            fontSize: '14px',
          }}
        >
          {isEnglish
            ? recommendation?.field_recommendation_en
            : recommendation?.field_recommendation_ru}
        </Typography>
        <RecommendationExamples
          key={recommendation?.id}
          examples={goodExamples}
          good
        />
      </div>
      <div className={classNames(s.heuristicContent)}>
        <div className={classNames(s.recommendationHeader)}>
          <div className={classNames(s.bad, s.recommendationIcon)}>
            <OkIcon />
          </div>
          <Typography
            sx={{
              fontSize: '16px',
            }}
          >
            {t('incorrect')}
          </Typography>
        </div>
        <Typography
          sx={{
            fontSize: '14px',
          }}
        >
          {isEnglish
            ? recommendation?.field_whybad_en
            : recommendation?.field_whybad_ru}
        </Typography>
        <RecommendationExamples
          key={recommendation?.id}
          examples={badExamples}
          good={false}
        />
      </div>
    </>
  )

  const secondLevelTitle = isEnglish
    ? heuristic?.field_he3_link_to_he1_parent?.field_heuristic_2_level_en
    : heuristic?.field_he3_link_to_he1_parent?.title
  const firstLevelTitle = isEnglish
    ? heuristic?.field_he3_he1_list?.field_heuristic_1_level_en
    : heuristic?.field_he3_he1_list?.title

  const tags: any[] = isEnglish
    ? heuristic?.field_field_htags_en
    : (heuristic?.field_htags as any)

  const visibleTags = isAllTagsVisible ? tags : tags?.slice(0, 3)

  const showToggleTagsVisibility = !isAllTagsVisible && tags.length > 3

  return (
    <div className={classNames(s.wrapper, className)}>
      <div className={classNames(s.heuristicAndDescriptionWrapper)}>
        {heuristic && (
          <div className={classNames(s.heuristic)}>
            <div className={classNames(s.heuristicContent)}>
              <div>
                <Typography
                  sx={{
                    fontSize: '16px',
                  }}
                >
                  {t('heuristic')}
                </Typography>
                <Typography
                  color="rgba(29, 33, 50, 0.7)"
                  sx={{
                    mb: 1,
                    fontSize: '12px',
                    maxWidth: '410px',
                  }}
                >
                  § {firstLevelTitle} → {secondLevelTitle}
                </Typography>
                <Typography className={s.description}>
                  {getParagraphsFromString(
                    String(
                      isEnglish
                        ? heuristic?.field_description_en
                        : heuristic?.field_description_ru
                    )
                  )?.map((paragraph, index) => (
                    <>
                      {index !== 0 && (
                        <>
                          <br />
                        </>
                      )}
                      {paragraph}
                    </>
                  ))}
                </Typography>
              </div>
              {heuristic && (
                <div className={s.tags}>
                  {visibleTags?.map((tag: any) => (
                    <div key={tag.name} className={s.tag}>
                      {tag.name}
                    </div>
                  ))}
                  {showToggleTagsVisibility && (
                    <Button
                      onClick={() => setAllTagsVisible(true)}
                      variant="text"
                      endIcon={<ExpandMoreIcon />}
                      size="small"
                      className={s.moreTagsButton}
                    >
                      {t('more')}
                    </Button>
                  )}
                </div>
              )}
              {heuristicMaterials.length > 0 && (
                <div className={classNames(s.materialsHeader)}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                    }}
                  >
                    {t('relatedMaterials')} ({heuristicMaterials.length})
                  </Typography>
                  <Button
                    variant="text"
                    className={classNames(s.materialsButton)}
                    endIcon={
                      <ExpandMoreIcon
                        style={{
                          transform: isMaterialsVisible
                            ? 'rotate(180deg)'
                            : 'none',
                        }}
                      />
                    }
                    onClick={() => setMaterialsVisible(!isMaterialsVisible)}
                  >
                    {isMaterialsVisible ? t('hide') : t('show')}
                  </Button>
                </div>
              )}
              {isMaterialsVisible && (
                <div className={s.materials}>
                  {articles?.map((item: any) =>
                    renderMaterial(item, 'article')
                  )}
                  {videos?.map((item: any) => renderMaterial(item, 'video'))}
                </div>
              )}
            </div>
            {renderRecommendations()}
          </div>
        )}
      </div>

      {onClose && (
        <div className={s.closeButton} onClick={onClose}>
          <CloseIcon />
        </div>
      )}
    </div>
  )
}
