import { useProjectContext } from 'features/head/context'
import s from './QuickAuth.module.css'
import { Button, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface QuickAuthProps {
  fun: string
  projectId: string
}

export const QuickAuth = ({ fun, projectId }: QuickAuthProps) => {
  const { t } = useTranslation()

  const { setFunPassed } = useProjectContext()

  useEffect(() => {
    const passStorage = localStorage.getItem(`pass-${projectId}`)

    if (passStorage) {
      if (passStorage === fun) {
        setFunPassed(true)
      } else {
        localStorage.removeItem(`pass-${projectId}`)
      }
    }
  }, [fun, projectId, setFunPassed])

  const [password, setPassword] = useState('')
  const [isError, setError] = useState(false)

  const onSubmit = () => {
    if (atob(fun) === password) {
      setFunPassed(true)

      localStorage.setItem(`pass-${projectId}`, btoa(password))
    } else {
      setError(true)
    }
  }

  return (
    <div className={s.wrapper}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
        className={s.form}
      >
        <TextField
          label={t('password')}
          value={password}
          onChange={(e) => {
            setError(false)
            setPassword(e.target.value)
          }}
          error={isError}
          fullWidth
          autoFocus
        />
        <Button sx={{ mt: 2 }} variant="contained" fullWidth type="submit">
          {t('continue')}
        </Button>
      </form>
    </div>
  )
}
