import { Assessment, useHeuristics } from 'entities/assessment'

import s from './ReportFindDetails.module.css'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ReactComponent as BookIcon } from '../../../../assets/book.svg'
import { FindSelect } from 'features/assessment/find/FindSelect'
import {
  getCriticalityOptions,
  getFindTypeOptions,
} from 'features/assessment/find/lib'
import { ReportScreenCanvas } from '../ReportScreenCanvas'
import { Report } from 'widgets/report/types'
import { useProject } from 'shared/model/projects'
import { useEffect, useState } from 'react'
import { ReportDetailsContent } from './ReportDetailsContent'
import classNames from 'classnames'

interface ReportFindDetailsProps {
  assessment: Assessment
  number: number
  screenId: string
  fragmentId: string
  report: Report
  recommendation: any
  heuristicObject: any
  className?: string
}

export const ReportFindDetails = ({
  assessment,
  number,
  fragmentId,
  report,
  screenId,
  recommendation,
  heuristicObject,
  className,
}: ReportFindDetailsProps) => {
  const { t } = useTranslation()
  const [activeHeuristicId, setActiveHeuristicId] = useState('')

  const { data: project } = useProject()

  const heuristics = useHeuristics({
    tags: [],
    projectId: project?.id,
  })

  const heuristic = heuristics?.find(
    (item) => item.id === assessment.heuristicId
  )

  const negative = assessment.findType === 'ux-problem'
  const negativeName = heuristic?.nameNegative || heuristic?.name
  const heuristicName = negative ? negativeName : heuristic?.name

  const criticalityOption = getCriticalityOptions().find(
    (item) => item.value === assessment.type
  )
  const criticalityName = criticalityOption?.name
  const criticalityLabel = criticalityName ? `: ${criticalityName}` : ''

  useEffect(() => {
    const onKeyDownHandle = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (activeHeuristicId) {
          setActiveHeuristicId('')
        }
      }
    }

    document.addEventListener('keydown', onKeyDownHandle)

    return () => document.removeEventListener('keydown', onKeyDownHandle)
  }, [activeHeuristicId])

  return (
    <div className={classNames(s.wrapper, className)}>
      <div className={s.findForm}>
        <Typography className={s.title}>
          {t('find')} №{number}
        </Typography>
        {!!assessment.heuristicId && (
          <>
            <div className={s.heuristicTitle}>
              <Typography className={s.subtitle}>{t('heuristic')}</Typography>
              <div
                onClick={() =>
                  setActiveHeuristicId(
                    activeHeuristicId ? '' : assessment.heuristicId
                  )
                }
                className={classNames(s.heuristicButtonWrapper, {
                  [s.heuristicButtonWrapperActive]: !!activeHeuristicId,
                })}
              >
                <BookIcon />
              </div>
            </div>
            <Typography className={s.text}>{heuristicName}</Typography>
          </>
        )}

        <Typography className={s.subtitle}>
          {t('findType')}
          {assessment.type ? `: ${t('criticality')}` : ''}
        </Typography>
        <div className={s.text}>
          <FindSelect
            value={assessment.findType}
            onChange={() => null}
            options={getFindTypeOptions(
              criticalityLabel,
              criticalityOption?.color
            )}
            disabled
            error={false}
            isReportView
          />
        </div>

        <Typography className={s.subtitle}>{t('findName')}</Typography>
        <Typography className={s.text}>{assessment.name}</Typography>

        {assessment.comment && (
          <>
            <Typography className={s.subtitle}>{t('description')}</Typography>
            <Typography className={s.text}>{assessment.comment}</Typography>
          </>
        )}

        {assessment.recommendation && (
          <>
            <Typography className={s.subtitle}>
              {t('recommendation')}
            </Typography>
            <Typography className={s.text}>
              {assessment.recommendation}
            </Typography>
          </>
        )}
      </div>

      {activeHeuristicId && project?.id && (
        <ReportDetailsContent
          heuristic={heuristicObject}
          recommendation={recommendation}
          projectId={project?.id}
          className={s.heuristicInfo}
          onClose={() => setActiveHeuristicId('')}
        />
      )}

      {project && (
        <ReportScreenCanvas
          screenId={screenId}
          fragmentId={fragmentId}
          assessmentId={assessment?.id}
          project={project}
          report={report}
        />
      )}
    </div>
  )
}
