import { Button } from '@mui/material'
import classNames from 'classnames'
import Color from 'color'

import s from './FindSelectButton.module.css'

interface FindSelectButtonProps {
  selected: boolean
  onClick: VoidFunction
  children: React.ReactNode
  icon: React.ReactNode
  outlined?: boolean
  noGrow?: boolean
  disabled?: boolean
  color?: string
}

export const FindSelectButton = ({
  icon,
  children,
  onClick,
  selected,
  outlined,
  noGrow,
  disabled,
  color = 'rgba(233, 242, 255, 1)',
}: FindSelectButtonProps) => {
  const defaultVariant = outlined ? 'outlined' : 'text'
  const variant = selected ? 'contained' : defaultVariant

  return (
    <Button
      className={classNames(s.button, {
        [s.buttonContained]: selected,
        [s.outlined]: variant === 'outlined',
        [s.noGrow]: noGrow,
        [s.disabled]: disabled,
        [s.disabledAndNotSelected]: disabled && !selected,
      })}
      variant={variant}
      onClick={onClick}
      startIcon={icon}
      sx={
        color && selected
          ? {
              backgroundColor: color,
              border: `1px solid ${Color(color).darken(0.5)}`,
              '&:hover': {
                backgroundColor: color,
              },
            }
          : null
      }
    >
      {children}
    </Button>
  )
}
