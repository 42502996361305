import { useState } from 'react'
import s from './AssessmentPanelFragmentName.module.css'
import { TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  useDeleteScreenFragment,
  useUpdateScreenFragmentName,
} from 'entities/assessment'

import { ReactComponent as TrashIcon } from '../../../../assets/trash.svg'

interface AssessmentPanelFragmentNameProps {
  editable: boolean
  name: string
  isCustom: boolean
  screenId: string
  fragmentId: string
}

export const AssessmentPanelFragmentName = ({
  editable,
  name,
  isCustom,
  fragmentId,
  screenId,
}: AssessmentPanelFragmentNameProps) => {
  const { t } = useTranslation()

  const [fragmentName, setFragmentName] = useState<string>(name || '')

  const updateScreenFragmentName = useUpdateScreenFragmentName()

  const deleteScreenFragment = useDeleteScreenFragment()

  const updateFragmentName = () =>
    updateScreenFragmentName({
      fragmentId,
      screenId,
      name: fragmentName,
    })

  return (
    <div className={s.wrapper}>
      {editable ? (
        <>
          <TextField
            label={isCustom ? t('customFragment') : t('fragment')}
            value={fragmentName}
            onBlur={updateFragmentName}
            onChange={(e) => setFragmentName(e.target.value)}
            classes={{
              root: s.input,
            }}
            size="small"
          />
          <button
            onClick={() =>
              deleteScreenFragment({
                fragmentId,
                onSuccess: () => null,
                screenId,
              })
            }
            className={s.deleteButton}
          >
            <TrashIcon />
          </button>
        </>
      ) : (
        <Typography
          sx={{
            fontSize: '20px',
          }}
        >
          {fragmentName}
        </Typography>
      )}
    </div>
  )
}
