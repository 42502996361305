import { ComponentType } from 'react'
import ReactFlow, { Controls } from 'reactflow'
import 'reactflow/dist/style.css'
import s from './Flow.module.css'
import { FlowScreenNode } from 'features/flow/screen'
import {
  FlowTransitionNode,
  TransitionProvider,
} from 'features/flow/transition'
import {
  FlowContainerNodeProps,
  FlowNodeType,
  FlowScreenNodeProps,
  FlowTransitionNodeProps,
  useFlowEdges,
  useFlowNodes,
} from 'entities/flow'
import { FlowContainerNode } from 'features/flow/container'
import { useProject, useRemoteProjectRefresh } from 'shared/model/projects'
import { useHasWritePermission } from 'entities/permissions'
import { ProjectHead } from 'features/head/ProjectHead'
import { useProjectContext } from 'features/head/context'
import { QuickAuth } from 'features/quick-auth'

// we define the nodeTypes outside of the component to prevent re-renderings
// you could also use useMemo inside the component
export const nodeTypes: Record<
  FlowNodeType,
  | ComponentType<FlowScreenNodeProps>
  | ComponentType<FlowTransitionNodeProps>
  | ComponentType<FlowContainerNodeProps>
> = {
  screen: FlowScreenNode,
  transition: FlowTransitionNode,
  container: FlowContainerNode,
}

export const Flow = () => {
  const nodes = useFlowNodes()
  const edges = useFlowEdges()

  useRemoteProjectRefresh()

  const hasWritePermission = useHasWritePermission()

  const { data: projectData } = useProject()
  const { funPassed } = useProjectContext()

  if (projectData?.fun && !funPassed) {
    return <QuickAuth fun={projectData?.fun} projectId={projectData.id} />
  }

  return (
    <div className={s.wrapper}>
      <ProjectHead />
      <div className={s.flowWrapper}>
        <TransitionProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            fitView
            style={{
              backgroundColor: 'rgba(29, 33, 50, 0.04)',
            }}
            minZoom={0.3}
            maxZoom={3}
          >
            <Controls />
          </ReactFlow>
          {/* {hasWritePermission && <ActionsPanel />} */}
        </TransitionProvider>
      </div>
    </div>
  )
}
