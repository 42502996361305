import { Report, ReportDetailsCoord } from 'widgets/report/types'
import s from './ReportDetails.module.css'
import { useProject } from 'shared/model/projects'
import { ReportFindDetails } from './ReportFindDetails'

interface ReportDetailsProps {
  coord: ReportDetailsCoord | null
  report: Report
  isMobile: boolean
  isFinalReport: boolean
  className?: string
}

export const ReportDetails = ({
  coord,
  report,
  isMobile,
  isFinalReport,
  className,
}: ReportDetailsProps) => {
  const { data: project } = useProject()

  if (!coord || !project) {
    return null
  }

  const screenVariant = report.screens
    .find((screen) => screen.number === coord.reportScreenNumber)
    ?.screenVariants.find(
      (screenVariant) =>
        screenVariant.number === coord.reportScreenVariantNumber
    )

  const fragment = (
    isFinalReport
      ? screenVariant?.finalReportFragments
      : screenVariant?.fragments
  )?.find(
    (fragment) => fragment.number === coord.reportScreenVariantFragmentNumber
  )

  const assessment = fragment?.assessments.find(
    (assessment) => assessment.id === coord.assessmentId
  )

  const heuristic = report.heuristics.find(
    (item) => item.id === assessment?.heuristicId
  )

  const recommendation: any = Array.isArray(
    heuristic?.field_recommendation_how_to_fix
  )
    ? heuristic?.field_recommendation_how_to_fix?.[0]
    : null

  if (!assessment || !screenVariant || !fragment) {
    return null
  }

  const screenId = screenVariant.screenId
  const fragmentId = fragment.id

  return (
    <ReportFindDetails
      fragmentId={fragmentId}
      assessment={assessment}
      report={report}
      screenId={screenId}
      number={0}
      recommendation={recommendation}
      heuristicObject={heuristic}
      className={className}
    />
  )
}
