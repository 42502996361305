import {
  Fieldfixexamplesen,
  Fieldfixexamplesru,
} from 'entities/assessment/api-types'
import s from './RecommendationExamples.module.css'
import { useEffect, useState } from 'react'
import { Tab, Tabs, Typography } from '@mui/material'
import { ExamplePopup } from './ExamplePopup'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'

interface RecommendationExamplesProps {
  good: boolean
  examples: (Fieldfixexamplesen | Fieldfixexamplesru)[]
}

export const RecommendationExamples = ({
  examples,
  good,
}: RecommendationExamplesProps) => {
  const [tab, setTab] = useState(0)
  const [isFullScreen, setFullScreen] = useState(false)

  useEffect(() => {
    if (isFullScreen) {
      const keydownHandle = (event: KeyboardEvent) => {
        const isArrowLeft = event.key === 'ArrowLeft'
        const isArrowRight = event.key === 'ArrowRight'

        const isArrowLeftOrArrowRight = isArrowLeft || isArrowRight

        if (!isArrowLeftOrArrowRight) return

        if (isArrowRight) {
          if (tab < examples.length - 1) {
            setTab((prev) => prev + 1)
          }
          return
        }

        if (isArrowLeft) {
          if (tab > 0) {
            setTab((prev) => prev - 1)
          }
          return
        }
      }

      document.addEventListener('keydown', keydownHandle)

      return () => document.removeEventListener('keydown', keydownHandle)
    }
  }, [isFullScreen, examples.length, tab])

  if (!examples.length) {
    return null
  }

  const selectedExample: any = examples[tab]

  const exampleMedia = selectedExample?.field_media?.[0]

  const previewVideo =
    exampleMedia?.field_video_v2?.thumbnail?.image_style_uri?.large

  const previewImageUrl =
    previewVideo || exampleMedia?.field_example_image?.image_style_uri?.large

  const isVideo = !!previewVideo

  const description =
    exampleMedia?.field_video_v2_description ||
    exampleMedia?.field_image_description

  const sourceUrl =
    exampleMedia?.field_video_v2?.field_media_video_file?.uri?.url ||
    exampleMedia?.field_example_image?.uri?.url

  return (
    <div className={s.wrapper}>
      <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)}>
        {examples.map((example) => (
          <Tab
            key={example.id}
            label={example.field_example_name}
            classes={{
              selected: s.selectedTab,
              root: s.tab,
            }}
          />
        ))}
      </Tabs>
      {selectedExample && (
        <>
          <Typography
            sx={{
              fontSize: '14px',
              marginTop: '16px',
            }}
          >
            {description}
          </Typography>
          {!!previewImageUrl && (
            <div onClick={() => setFullScreen(true)} className={s.imageWrapper}>
              <img
                className={s.image}
                alt={description}
                src={previewImageUrl}
              />
              {isVideo && <PlayCircleIcon className={s.videoIcon} />}
            </div>
          )}
        </>
      )}
      <ExamplePopup
        type={isVideo ? 'video' : 'image'}
        url={sourceUrl}
        name={selectedExample.field_example_name}
        open={isFullScreen}
        onClose={() => setFullScreen(false)}
        poster={isVideo ? previewVideo : undefined}
        description={description}
        good={good}
      />
    </div>
  )
}
