import { ScreenFragment } from 'entities/assessment'
import { fabric } from 'fabric'
import { drawDarkRects } from './drawDarkRects'

interface LoadImageParams {
  imageUrl: string
  setImage: (value: fabric.Image) => void
}

export const loadImage = ({ imageUrl, setImage }: LoadImageParams) => {
  fabric.Image.fromURL(imageUrl, (oImg) => {
    oImg.set('selectable', false)
    oImg.set('objectCaching', false)
    setImage(oImg)
  })
}

interface DrawReportCanvasParams {
  canvas: fabric.Canvas
  fragment: ScreenFragment
}

export const drawReportCanvas = ({
  canvas,
  fragment,
}: DrawReportCanvasParams) => {
  const imgElement = document.getElementById('canvas-image')

  if (imgElement) {
    const imgInstance = new fabric.Image(imgElement as any, {
      left: 0,
      top: 0,
      selectable: false,
    })
    canvas.add(imgInstance)
  }

  if (fragment.height && fragment.width) {
    drawDarkRects({
      fragment,
      canvas,
    })
  }

  canvas.renderAll()
}
